import 'regenerator-runtime/runtime'

import api from "@/services/apiService"
import Encrypt from "@/helpers/encrypt"

class LotteryService {

  /**
   * ดึงข้อมูลงวดหวย
   */
  getRounds(date=null) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'round' + (date?'/'+date:''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * ดึงข้อมูลรอบหวย
   */
  getRound(roundId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'round/' + roundId)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * ดึงข้อมูลเลขเต็ม
   */
  getRoundSoldout(roundId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_TICKET + 'round/' + roundId + '/soldout')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * บันทึกโพย
   */
  createTicket(ticket) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_SUBMIT + 'ticket', ticket)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  createLottosetTicket(ticket) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_TICKET + 'ticket-sets', ticket)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * ดึงข้อมูลหวยชุด
   */
  getLottosetRounds(date=null) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'round/lottoset' + (date?'/'+date:''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * ดึงข้อมูลรอบหวยชุด
   */
  getLottosetRound(roundId, rateId) {
    return new Promise((resolve, reject) => {
      api.get(`${process.env.VUE_APP_API_MAIN}round/lottoset/${roundId}/${rateId}`)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * ตรวจสอบสถานะ
   */
  // queueStatus(queueId) {
  //   return new Promise((resolve, reject) => {
  //     api.get(process.env.VUE_APP_API_TICKET + 'ticket/queue-status/' + queueId)
  //     .then(function(response) {
  //       resolve(response.data)
  //     })
  //     .catch(function(error) {
  //       console.log(error)
  //       reject(error)
  //     });
  //   });
  // }

  /**
   * ดึงข้อมูลการถือหุ้นของบริษัท
   */
  getRoundHoldings(roundId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_TICKET + 'round/' + roundId + '/holdings')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }
}

export default new LotteryService()
